import Swiper from 'swiper/swiper-bundle';

$('.js-slider-hero .swiper').each( (i, slider) => {
	const swiper = new Swiper(slider, {
		slidesPerView: 1,
		spaceBetween: 58,
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},

		navigation: {
			nextEl: '.js-slider-next',
			prevEl: '.js-slider-prev',
		},
	});

	const $sliderBg = $('.slider-hero .slider__bg').find("video, img");

	swiper.on('slideChange', function(i) {
		let indexSlide = this.realIndex;

		$sliderBg.each(function(i) {
			if (indexSlide == i) {
				$sliderBg[i].classList.add('is-active');
			} else {
				$sliderBg[i].classList.remove('is-active');
			}
		})
	})
});

$('.js-slider-testimonials .swiper').each( (i, slider) => {
	const swiper = new Swiper(slider, {
		slidesPerView: 1,
		loop: true,
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
			clickable: true,
		},
	});
});

$('.js-slider-timeline .swiper').each( (i, slider) => {
	const swiper = new Swiper(slider, {
		slidesPerView: 'auto',
		navigation: {
			nextEl: '.js-slider-next',
			prevEl: '.js-slider-prev',
		},
	});
});
