import { $win, $body, events } from '../utils/globals';

$win.on('load', () => {
	$('.js-loader').addClass('is-animating');

	const lastElement = $('.js-loader-end');
	const lastElementDesktop = $('.js-loader-end-desktop');
	const $loaderEnd = lastElementDesktop.is(':visible') ? lastElementDesktop : lastElement;
	let animationComplete = false;

	const animation = () => {
		if (animationComplete) return;
		events.emit("site-loader-exiting");
		$('.js-loader').delay(500).fadeOut(() => {
			$body.addClass('is-loaded');
			events.emit("site-loader-complete");
			animationComplete = true;
		});
	};

	$loaderEnd.one('transitionend', animation);

	setTimeout(() => animation(), 3000);
});
