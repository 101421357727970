/*
 * Polyfills
 */
import './polyfills/object-fit';

/*
 * Modules
 */

import './modules/nav-toggle';
import './modules/initSlider';
import './modules/initMfp';
import './modules/equalizeHeight';
import './modules/initTabs';
import './modules/loader';
import './modules/resource-category-filter';
import './modules/filters';
import categoryNavigation  from './modules/post-category-filters';
import './modules/filters'
import './modules/scrollToSection'
import './modules/heroMargin'


categoryNavigation();