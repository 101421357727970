import { $win, $document, $body } from '../utils/globals';

$('.js-scroll-to').on('click', function () {
	const sectionId = $(this).attr('href');
	const $sectionTarget = $(sectionId);
	const sectionTop = $sectionTarget.offset().top;

	$('html').animate ({
		scrollTop: sectionTop
	}, 1000)
});