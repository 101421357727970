import { $win } from '../utils/globals';

$win.on('load resize', function() {
	const breakpoint = window.matchMedia('(max-width: 767px), screen and (max-width: 812px) and (orientation: landscape)');
	const $hero = $('.hero');
	const $heroInnerHeight = $('.hero .hero__inner').outerHeight();
	const $heroInnerHalfHeight = $('.hero .hero__inner').outerHeight()/2;
	const $heroWrapper = $('.hero .hero__wrapper');

	$heroWrapper.css('margin-bottom', $heroInnerHalfHeight);

	const breakpointChecker = () => {
		if(breakpoint.matches) {
			if($('.hero').hasClass('hero--small')) {
				$heroWrapper.css('margin-bottom', '');
			}
		}
		else {
			$heroWrapper.css('margin-bottom', $heroInnerHalfHeight);
		}
	}

	$(breakpoint).on('change', breakpointChecker);
	breakpointChecker();
})