import 'magnific-popup';
import { $html, $body, events } from '../utils/globals';

const $popupBio = $('.js-popup-bio');
const $popupFilter = $('.js-popup-filter');
const $initAfterLoad = $(".js-mfp-initOnLoad");

$popupBio.on('click', function(e){
	e.preventDefault();

	let $popupContainer = $(this).siblings('.popup-bio');

	$.magnificPopup.open({
		items: {
			src: $popupContainer
		},
		fixedContentPos: true,
		closeOnBgClick: true,

		type:'inline',
	}, 0);
});

$popupFilter.on('click', function(e){
	e.preventDefault();

	let $popupContainer = $(this).siblings('.popup-filter');

	$.magnificPopup.open({
		items: {
			src: $popupContainer
		},
		fixedContentPos: true,
		closeOnBgClick: true,
		closeOnContentClick: true,
		mainClass: 'mfp-filter',
		type:'inline',
	}, 0);
});

if ( $initAfterLoad.length ) {
	events.on("site-loader-complete", showBio);

	function showBio() {
		$html.add($body).animate({
			scrollTop: $initAfterLoad.offset().top,
		}, 500);
		setTimeout(() => { $initAfterLoad.find(".js-popup-bio").trigger("click"); }, 500);
	}
}