$('.js-filter-listing').on('click', function(e) {
	e.preventDefault();

	$(this).closest('li')
			.addClass('is-active')
			.siblings()
			.removeClass('is-active');

	$($(this).attr('href'))
			.addClass('is-active')
			.siblings()
			.removeClass('is-active');
});
