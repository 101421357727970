import { $document } from "../utils/globals";

$('.js-resource-category').on('click', function(e){
	e.preventDefault();
	const $btn = $(this);
	const categoryID = $btn.data('id');
	$.ajax({
		url: window.location.href,
		type: "GET",
		data: {
			app_selected_category: categoryID,
		},
		beforeSend: function() {
			$('body').addClass('loading');
		},
		success: function (response) {
			$btn.parent().siblings().removeClass('is-active');
			$btn.parent().addClass('is-active');

			const resources = $(response).find(".js-filtered-category").html();
			$(".js-filtered-category").html(resources);
		},
		complete:function(){
			$('body').removeClass('loading');
		},
		error: function (jqXHR) {
			window.alert(jqXHR);
		},
	});
});