import { $document } from "../utils/globals";

const categoryNavigation = () => {
	$document.on("click", ".js-filter-posts", function (e) {
		e.preventDefault();
		const $this      = $(this);
		const categoryID = $this.data("id");

		$this.siblings().removeClass('is-current');
		$this.addClass('is-current');


		$.ajax({
			url: "/research/",
			type: "GET",
			data: {
				app_category: categoryID,
			},
			beforeSend: function() {
				$('body').addClass('loading');
			},
			success: function (response) {
				console.log(response);
				const newProjects = $(response).find(".js-post-category-filtration").html();
				$(".js-post-category-filtration").html(newProjects);
			},
			error: function (error) {
				console.log(error);
			},
			complete:function(){
				$('body').removeClass('loading');
			}
		});
	});

	$document.on("click", ".js-clear-filters", function (e) {
		e.preventDefault();
		const $this      = $(this);
		const categoryID = '';

		$('.js-filter-posts').each(function() {
			$(this).removeClass('is-current');
		});


		$.ajax({
			url: "/research/",
			type: "GET",
			data: {
				app_category: categoryID,
			},
			beforeSend: function() {
				$('body').addClass('loading');
			},
			success: function (response) {
				const newProjects = $(response).find(".js-post-category-filtration").html();
				$(".js-post-category-filtration").html(newProjects);
			},
			error: function (error) {
				console.log(error);
			},
			complete:function(){
				$('body').removeClass('loading');
			}
		});
	});
};


export default categoryNavigation;