import { $win, $body } from '../utils/globals';

$('.js-toggle').on('click', function(e) {
	e.preventDefault();

	$(this).toggleClass('is-active');

	$body.toggleClass('has-active-nav');
})

$win.on('load', function() {
	const $subMenuLinks = $('.menu-item-has-children > a');

	$subMenuLinks.append('<span class="arrow"></span>');
})

$win.on('load resize', function() {
	const breakpoint = window.matchMedia('(max-width: 1023px)');

	const $subMenuLinks = $('.menu-item-has-children > a > .arrow');

	const breakpointChecker = () => {
		$subMenuLinks.on('click', function(event) {
			event.preventDefault();

			if(breakpoint.matches) {
				const $this = $(this);

				$this
					.closest('.menu-item-has-children')
					.toggleClass('is-active')
						.find('.sub-menu')
						.stop()
						.slideToggle()
						.end()
					.siblings()
						.removeClass('is-active')
						.end()
					.siblings()
						.find('.sub-menu')
						.stop()
						.slideUp();
			}
		})

		if(breakpoint.matches) {
			$('.menu-item-has-children').not('.is-active').find('.sub-menu').hide();

		} else {
			$('.menu-item-has-children').not('.is-active').find('.sub-menu').css('display', '');
			$('.js-toggle').removeClass('is-active');
			$body.removeClass('has-active-nav')
		}
	}

	$(breakpoint).on('change', breakpointChecker);
	breakpointChecker();
})