$(window).on('load resize', function() {
	const breakpoint = window.matchMedia('(max-width: 767px), screen and (max-width: 812px) and (orientation: landscape)');

	$('.bios .bios__items').each( function(){
		var bioHead = $(this).find('.bio .bio__head');
		var bioInfo = $(this).find('.bio .bio__info');

		const breakpointChecker = () => {
			if(breakpoint.matches) {
				bioHead.css('height', '');
				bioInfo.css('height', '');
			}
			else {
				bioHead.equalizeHeight();
				bioInfo.equalizeHeight();
			}
		}

		$(breakpoint).on('change', breakpointChecker);
		breakpointChecker();

	});
})

$.fn.equalizeHeight = function(){
	var maxHeight = 0, itemHeight;

	this.css('height', '');

	for (var i = 0; i < this.length; i++){
		itemHeight = $(this[i]).height();
		if (maxHeight < itemHeight) {
			maxHeight = itemHeight;
		}
	}

	return this.height(maxHeight);
};
